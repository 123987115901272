import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`OHS 5×2\\@85% 1RM`}</p>
    <p>{`Overhead Band Pull Aparts 5×10`}</p>
    <p>{`then,`}</p>
    <p>{`6-5-4-3-2-1 reps each:`}</p>
    <p>{`Wall Walk`}</p>
    <p>{`Full (Squat) Snatch (115/75)(RX+ 135/95)`}</p>
    <p>{`for time.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      